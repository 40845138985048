import React from "react";
import {Route, Switch} from "react-router-dom";
import {getLogInPath, getLogOutPath, getTokenPath, getChangePasswordPath} from "./paths";
import {LoggedOut} from "../pages/loggOut/LoggedOut";
import {LogIn} from "../pages/logIn/LogIn";
import {PrivateRoute} from "./PrivateRoute";
import {DisplayToken} from "../pages/tokens/DisplayToken";
import {Route404} from "../pages/404/Route404";
import {ChangePassword} from "../pages/changePassword/ChangePassword";

export const AppRoutes: React.FunctionComponent = () => (
    <Switch>
        <Route path={getLogInPath()} exact component={LogIn}/>
        <Route path={getLogOutPath()} exact component={LoggedOut}/>
        <PrivateRoute path={getTokenPath()} exact component={DisplayToken}/>
        <PrivateRoute path={getChangePasswordPath()} exact component={ChangePassword}/>
        <Route path={"/"} exact component={LogIn}/>
        <Route path="*" component={Route404}/>
    </Switch>
)