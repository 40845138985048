import React, { useContext } from "react";
import { Route, RouteProps } from "react-router-dom";
import { LogIn } from "../pages/logIn/LogIn";
import { UserContext } from "../context/userContext";


export const PrivateRoute: React.FunctionComponent<RouteProps> = ({ component, ...options }) => {
    const { user } = useContext(UserContext);
    const finalComponent = user.id ? component : LogIn;
    return <Route {...options} component={finalComponent} />;
  };