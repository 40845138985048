import React from 'react';
import {GridContainer} from "../../components/shared/layout/GridContainer";
import {GridItem} from "../../components/shared/layout/GridItem";

type Route404Props = {}

export const Route404: React.FunctionComponent<Route404Props> = () => {
    return (<GridContainer>
        <GridItem>
            <h2>404 Pfad nicht gefunden</h2>
        </GridItem>
    </GridContainer>);
}