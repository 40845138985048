import React, {useEffect} from 'react';
import {TableCell, TableHead, TableRow, TableSortLabel} from "@material-ui/core";
import {dFlex} from "../../constants/styles";
import {SearchBar} from "../../components/shared/SearchBar";
import {SearchObject} from "./DisplayToken";
import {Order} from "../../types/utilTypes";
import {usePrevious} from "../../utils/functions";

const tableH = [
    {
        id: 0,
        sortable: true,
        key: "domain",
        label: "Domain"
    },
    {
        id: 1,
        sortable: true,
        key: "token",
        label: "Token"
    },
    {
        id: 2,
        sortable: true,
        key: "created_at",
        label: "Erstellt am"
    },
    {
        id: 3,
        sortable: false,
        key: "",
        label: "Aktionen"
    }
]

type DisplayTableHeadProps = {
    search: { [key: string]: SearchObject },
    isSorting: boolean,
    setSearch: (search: { [key: string]: SearchObject }) => void,
    setIsSorting: (sort: boolean) => void,
    sortDomains: (orderBy: string, order: string) => void,
}

export const DisplayTableHead: React.FunctionComponent<DisplayTableHeadProps> = (
    {isSorting, setSearch, setIsSorting, sortDomains, search}
) => {
    const [order, setOrder] = React.useState<Order>('desc');
    const [orderBy, setOrderBy] = React.useState<string>("");
    const prevOrderKey = usePrevious(orderBy);

    const initOrder = (orderByKey: string) => {
        setIsSorting(true);
        setOrderBy(orderByKey)
        setOrder(orderByKey !== prevOrderKey ? "asc" : (order === "asc" ? "desc" : "asc"))
    }

    const searchColumn = (value: SearchObject["value"], column: SearchObject["column"]) => setSearch({
        ...search,
        [column]: {
            column,
            value
        }
    });

    useEffect(() => sortDomains(orderBy, order), [order, orderBy])

    return (
        <TableHead component={"div"}>
            <TableRow component={"div"}>
                {tableH.map((head, index) =>
                    <TableCell
                        key={index}
                        sortDirection={orderBy === head.key ? order : false}
                        component={"div"}
                    >
                        {head.sortable ? (
                            <div style={dFlex}>
                                <SearchBar handleChange={(event) => searchColumn(event.target.value, head.key)}
                                           placeholder={head.label}
                                           searchKey={search[head.key] ? search[head.key].value : ""}
                                />
                                <TableSortLabel
                                    active={orderBy === head.key}
                                    direction={orderBy === head.key ? order : 'asc'}
                                    onClick={() => initOrder(head.key)}
                                    disabled={isSorting}
                                    component={"div"}
                                >
                                    sortieren
                                </TableSortLabel>
                            </div>) : head.label
                        }
                    </TableCell>
                )}
            </TableRow>
        </TableHead>
    );
}