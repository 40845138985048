import {Order} from "../types/utilTypes";

const formatValue = (value: any, key: string) => {
    if (typeof value === "string") {
        return value.toLowerCase();
    }
    return value;
}

export const quickSort = (arr: [{ [key: string]: any }], leftPos: number, rightPos: number, key: string, order: Order = "asc") => {
    if(order === "asc") {
        quickSortAsc(arr, leftPos, rightPos, arr.length, key);
    }
    if(order === "desc") {
        quickSortDesc(arr, leftPos, rightPos, arr.length, key);
    }
}

export const quickSortAsc = (arr: [{ [key: string]: any }], leftPos: number, rightPos: number, arrLength: number, key: string) => {
    let initialLeftPos = leftPos;
    let initialRightPos = rightPos;
    let direction = true;
    let pivot = rightPos;
    while ((leftPos - rightPos) < 0) {
        if (direction) {
            if (formatValue(arr[pivot][key], key) < formatValue(arr[leftPos][key], key)) {
                quickSortAsc.swap(arr, pivot, leftPos);
                pivot = leftPos;
                rightPos--;
                direction = !direction;
            } else
                leftPos++;
        } else {
            if (formatValue(arr[pivot][key], key) < formatValue(arr[rightPos][key], key)) {
                rightPos--;
            } else {
                quickSortAsc.swap(arr, pivot, rightPos);
                leftPos++;
                pivot = rightPos;
                direction = !direction;
            }
        }
    }
    if (pivot - 1 > initialLeftPos) {
        quickSortAsc(arr, initialLeftPos, pivot - 1, arrLength, key);
    }
    if (pivot + 1 < initialRightPos) {
        quickSortAsc(arr, pivot + 1, initialRightPos, arrLength, key);
    }
}

quickSortAsc.swap = (arr: [{ [key: string]: object }], el1: number, el2: number) => {
    let swapedElem = arr[el1];
    arr[el1] = arr[el2];
    arr[el2] = swapedElem;
}

export const quickSortDesc = (arr: [{ [key: string]: any }], leftPos: number, rightPos: number, arrLength: number, key: string) => {
    let initialLeftPos = leftPos;
    let initialRightPos = rightPos;
    let direction = true;
    let pivot = rightPos;
    while ((leftPos - rightPos) < 0) {
        if (direction) {
            if (formatValue(arr[pivot][key], key) > formatValue(arr[leftPos][key], key)) {
                quickSortDesc.swap(arr, pivot, leftPos);
                pivot = leftPos;
                rightPos--;
                direction = !direction;
            } else
                leftPos++;
        } else {
            if (formatValue(arr[pivot][key], key) > formatValue(arr[rightPos][key], key)) {
                rightPos--;
            } else {
                quickSortDesc.swap(arr, pivot, rightPos);
                leftPos++;
                pivot = rightPos;
                direction = !direction;
            }
        }
    }
    if (pivot - 1 > initialLeftPos) {
        quickSortDesc(arr, initialLeftPos, pivot - 1, arrLength, key);
    }
    if (pivot + 1 < initialRightPos) {
        quickSortDesc(arr, pivot + 1, initialRightPos, arrLength, key);
    }
}
quickSortDesc.swap = (arr: [{ [key: string]: object }], el1: number, el2: number) => {
    let swapedElem = arr[el1];
    arr[el1] = arr[el2];
    arr[el2] = swapedElem;
}