import React from 'react';
import {TableCell} from "@material-ui/core";
import {Skeleton} from "@material-ui/lab";
import {TableCellBaseProps} from "@material-ui/core/TableCell/TableCell";

type SkeletonTableCellsProps = {
    colCount?: number,
    component?: React.ElementType<TableCellBaseProps>
}

export const SkeletonTableCells: React.FunctionComponent<SkeletonTableCellsProps> = ({colCount= 1, component= "td"}) => {
    return <React.Fragment>
        {[...Array(colCount)].map((value, index) => <TableCell key={index} component={component}>
                <Skeleton variant="rect" width={'100%'} height={24}/>
            </TableCell>
        )}
    </React.Fragment>
}