import React from 'react';
import {TableRow} from "@material-ui/core";
import {SkeletonTableCells} from "./SkeletonTableCells";

type SkeletonTableRowsProps = {
    colCount?: number,
    rowCount?: number
    component?: React.ElementType
}

export const SkeletonTableRows: React.FunctionComponent<SkeletonTableRowsProps> = ({colCount = 1, rowCount = 1, component = "tr"}) => {
    return <React.Fragment>
        {[...Array(rowCount)].map(
            (value, index) => (
                <TableRow key={index} component={component}>
                    <SkeletonTableCells colCount={colCount} component={component !== "tr" ? "div" : "td"} />
                </TableRow>
            ))}
    </React.Fragment>
}