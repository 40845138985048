import React from "react";
import {Grid, GridProps} from "@material-ui/core";
import {GridMarginType, margins} from "../../../constants/styles";
import {getGridMargin} from "../../../utils/gridFunctions";

export const GridItem: React.FunctionComponent<GridMarginType & GridProps> = ({marginType = "xs", paddingType = "m", children, ...props}) => {
    return (
        <Grid item {...props} style={{...getGridMargin(marginType, paddingType), padding: margins["xs"], ...props.style}}>
            {children}
        </Grid>
    )
}