import React, {useState} from 'react';
import {GridContainer} from "../../components/shared/layout/GridContainer";
import {GridItem} from "../../components/shared/layout/GridItem";
import {Button, Paper, TextField, Typography, TypographyProps} from "@material-ui/core";
import {Formik} from "formik";
import {changePassword} from "../../apiCalls/serverRequests/post";
import {UserContext} from "../../context/userContext";
import {margins} from "../../constants/styles";
import {API_ERROR} from "../../constants/api";

type ChangePasswordProps = {}

export const ChangePassword: React.FunctionComponent<ChangePasswordProps> = () => {
    const [message, setMessage] = useState<{ message: string, type: TypographyProps["color"] } | null>(null);
    return (
        <GridContainer>
            <GridItem xs={12}>
                <Paper style={{margin: "auto", padding: margins["m"], width: 800, maxWidth: "100%"}}>
                    <UserContext.Consumer>
                        {({user, setUser}) => (
                            <Formik<{ old_password: string, new_password: string, new_password_repeat: string }>
                                validateOnBlur={false}
                                validateOnChange={false}
                                validateOnMount={false}
                                initialValues={{
                                    old_password: "",
                                    new_password: "",
                                    new_password_repeat: "",
                                }}

                                onSubmit={(values, actions) => {
                                    if (values.new_password === values.new_password_repeat && user.id) {
                                        changePassword(values.old_password, values.new_password, user.id).then(
                                            ({result, status}) => {
                                                if (status.type === API_ERROR) {
                                                    setMessage({message: status.message, type: "error"});
                                                } else {
                                                    setMessage({message: result.message, type: "primary"})
                                                    actions.setValues({old_password: "", new_password: "", new_password_repeat: ""})
                                                }
                                                actions.setSubmitting(false);
                                            }
                                        )
                                    } else {
                                        if (values.old_password === values.new_password) {
                                            setMessage({
                                                message: "Das neue Passwort entspricht dem alten Passwort",
                                                type: "error"
                                            })
                                        } else {
                                            setMessage({message: "Passwörter stimmen nicht überein", type: "error"})
                                        }
                                        actions.setSubmitting(false);
                                    }
                                }}
                            >
                                {props => (
                                    <form onSubmit={props.handleSubmit}>
                                        <TextField
                                            style={{marginBottom: margins["m"]}}
                                            required
                                            variant="outlined"
                                            id="old_password"
                                            name="old_password"
                                            label="Altes Passwort"
                                            autoComplete="old_password"
                                            type="password"
                                            onChange={props.handleChange}
                                            value={props.values.old_password}
                                            fullWidth
                                        />
                                        <TextField
                                            style={{marginBottom: margins["m"]}}
                                            required
                                            variant="outlined"
                                            id="new_password"
                                            name="new_password"
                                            label="Neues Passwort"
                                            type="password"
                                            autoComplete="new_password"
                                            onChange={props.handleChange}
                                            value={props.values.new_password}
                                            fullWidth
                                        />
                                        <TextField
                                            style={{marginBottom: margins["m"]}}
                                            required
                                            variant="outlined"
                                            id="new_password_repeat"
                                            name="new_password_repeat"
                                            label="Neues Passwort wiederholen"
                                            autoComplete="new_password_repeat"
                                            type="password"
                                            onChange={props.handleChange}
                                            value={props.values.new_password_repeat}
                                            fullWidth
                                        />
                                        {message && message.message && (
                                            <Typography color={message.type} align="left" variant="body2"
                                                        style={{fontWeight: 600}}>
                                                {message.message}
                                            </Typography>
                                        )}
                                        <Button disabled={props.isSubmitting} color="primary"
                                                variant="contained" type="submit">
                                            Bestätigen
                                        </Button>
                                    </form>
                                )}
                            </Formik>
                        )}
                    </UserContext.Consumer>
                </Paper>
            </GridItem>
        </GridContainer>
    );
}