export const ADMIN_USER = 1;

export const APP_NAME = "Tokenverwaltung";

export const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'July',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
]