import React, {useState} from 'react';
import {Button, IconButton, Modal, Paper, TextField, Tooltip, Typography} from "@material-ui/core";
import {dFlex, margins, mAuto, mLeftAuto} from "../../../constants/styles";
import {Formik} from "formik"
import {DomainInput} from "./formFields/DomainInput";
import {addDomain, updateDomain} from "../../../apiCalls/serverRequests/post";
import {Domain} from "../../../types/api";
import {API_ERROR} from "../../../constants/api";
import {ApiController} from "../../../controller/ApiController";
import {LoadingAnimation} from "../../../components/shared/LoadingAnimation";
import {getDomains, getToken} from "../../../apiCalls/serverRequests/get";
import {GridContainer} from "../../../components/shared/layout/GridContainer";
import {GridItem} from "../../../components/shared/layout/GridItem";
import {faCopy, faRedo} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {copyToClipboard} from "../../../utils/functions";

type DomainForm = {
    domain: Domain["domain"],
    token: Domain["token"]
}

type AddDomainProps = {
    onSuccess: () => void
    open: boolean,
    setOpen: (open: boolean) => void
    initialValues: Domain | null;
}


export const AddDomain: React.FunctionComponent<AddDomainProps> = ({onSuccess, open, setOpen, initialValues}) => {
    const [domains, setDomains] = useState<[Domain] | null>(null);
    const [tooltipOpen, setTooltipOpen] = useState<boolean>(false);
    const [submitErrorMsg, setSubmitErrorMsg] = useState<string>("");

    const apiCall = (domain: Domain["domain"], token: Domain["token"]) =>
        initialValues && initialValues.id ? updateDomain(domain, token, initialValues.id) : addDomain(domain, token);

    const closeModal = () => setOpen(false);
    return (
        <React.Fragment>
            {!domains && <ApiController apiCall={getDomains} onSuccess={setDomains}/>}
            <Modal
                open={open}
                onClose={closeModal}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                style={dFlex}
            >
                <Paper style={{...mAuto, padding: margins["m"], width: 1000, maxWidth: "100%"}}>
                    {(!domains) ? (
                        <LoadingAnimation/>
                    ) : (
                        <Formik<DomainForm>
                            validateOnBlur={false}
                            validateOnChange={false}
                            validateOnMount={false}
                            initialValues={{
                                domain: (initialValues && initialValues.domain) || "",
                                token: (initialValues && initialValues.token) || "",
                            }}
                            onSubmit={(values) => {
                                if (values.domain !== "") {
                                    values.domain = values.domain.replace(/\s/g, '');
                                    apiCall(values.domain, values.token).then(({result, status}) => {
                                        if (status && status.type === API_ERROR) {
                                            setSubmitErrorMsg(status.message);
                                        } else {
                                            setSubmitErrorMsg("");
                                            onSuccess();
                                            closeModal();
                                        }
                                    });
                                }
                            }}>
                            {props => {
                                const handleAutoCompleteChange = (key: string, value: any) => props.setValues({
                                    ...props.values,
                                    [key]: value
                                })

                                const getRandomToken = () => getToken().then(({result}) => props.setValues({
                                    ...props.values,
                                    token: result.token
                                }))

                                const copyToken = () => copyToClipboard("token", () => [
                                    setTooltipOpen(true),
                                    setTimeout(() => setTooltipOpen(false), 2500)
                                ])
                                return (
                                    <form onSubmit={props.handleSubmit}>
                                        {props.values.token === "" && <ApiController
                                            apiCall={getToken} onSuccess={result => props.setValues({
                                            ...props.values,
                                            token: result.token
                                        })}
                                        />}
                                        <GridContainer>
                                            <GridItem xs={12}>
                                                <DomainInput
                                                    defaultValue={props.values.domain}
                                                    handleChange={(value) => handleAutoCompleteChange("domain", value)}
                                                    domains={domains}
                                                />
                                            </GridItem>
                                            <GridItem xs={12} style={dFlex}>
                                                <TextField
                                                    id="token"
                                                    name="token"
                                                    label="Token"
                                                    type="text"
                                                    onChange={props.handleChange}
                                                    value={props.values.token}
                                                    fullWidth
                                                    InputProps={{readOnly: true}}
                                                    variant="outlined"
                                                />
                                                <Tooltip open={tooltipOpen} title="Token erfolgreich kopiert"
                                                         placement="top">
                                                    <IconButton onClick={getRandomToken} style={{margin: "auto 4px"}}>
                                                        <FontAwesomeIcon style={{width: 24}} icon={faRedo}/>
                                                    </IconButton>
                                                </Tooltip>
                                                <IconButton onClick={copyToken} style={{margin: "auto 4px"}}>
                                                    <FontAwesomeIcon style={{width: 24}} icon={faCopy}/>
                                                </IconButton>
                                            </GridItem>
                                            <GridItem xs={12} style={{...dFlex, marginTop: margins["m"]}}>
                                                <div style={mLeftAuto}>
                                                    <Button variant="contained" color="default" onClick={closeModal}>
                                                        schließen
                                                    </Button>
                                                    <Button style={{marginLeft: margins["s"]}} variant="contained"
                                                            color="primary" type="submit"
                                                            disabled={props.values.domain === ""}>
                                                        Lizenz anlegen
                                                    </Button>
                                                </div>
                                            </GridItem>
                                            {submitErrorMsg !== "" && (
                                                <GridItem xs={12} style={dFlex}>
                                                    <div style={mLeftAuto}>
                                                        <Typography color="error">{submitErrorMsg}</Typography>
                                                    </div>
                                                </GridItem>
                                            )}
                                        </GridContainer>
                                    </form>
                                )
                            }}
                        </Formik>
                    )}
                </Paper>
            </Modal>
        </React.Fragment>
    );
}