import {User} from "../context/userContext";
import {ADMIN_USER} from "../constants/const";
import {validateUserSession} from "../apiCalls/serverRequests/post";
import {useEffect, useRef} from "react";

export const isAdmin = (userType: User['user_type']): boolean => !!(userType && userType === ADMIN_USER);

export const checkSession: () => Promise<User | null> = async () => {
    let user: User | null = null;
    if (localStorage.getItem('session_id') && localStorage.getItem('email')) {
        let session_id = localStorage.getItem('session_id')
        let email = localStorage.getItem('email')
        await validateUserSession(session_id, email).then(({result, status}) => {
            if (result && result.session_id === session_id) {
                user = result;
            }
        })
    }
    return user;
}

export const addUserToLocalStorage = (session_id: User["session_id"], email: User["email"]) => {
    localStorage.setItem('session_id', session_id);
    localStorage.setItem('email', email);
}

export const removeUserToLocalStorage = () => {
    localStorage.removeItem('session_id');
    localStorage.removeItem('email');
}

export const usePrevious = (value: any) => {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    });
    return ref.current;
}

type vn = (() => void) | null;

export const copyToClipboard = (inputId: string, successHandler: vn = null, errorHandler:vn = null) => {
    let copyText = document.querySelector("#" + inputId);
    if (copyText) {
        // @ts-ignore
        navigator.permissions.query({name: "clipboard-write"}).then(result => {
            if (result.state === "granted" || result.state === "prompt") {
                // @ts-ignore
                navigator.clipboard.writeText(copyText ? copyText.value : "").then(
                    () => successHandler && successHandler(), () => {
                    if (copyText) {
                        // @ts-ignore
                        copyText.select();
                        successHandler && successHandler();
                        document.execCommand("copy");
                    } else {
                        errorHandler && errorHandler();
                    }
                });
            }
        });
    }
}