import {User, USER_DEFAULT_VALUE, UserContext} from "../../context/userContext";
import React, {ReactElement, ReactNode, useState} from "react";
import {RouteComponentProps, withRouter} from "react-router";
import {getLogOutPath} from "../../routes/paths";
import {History} from 'history';
import {removeUserToLocalStorage} from "../../utils/functions";
import {destroySession} from "../../apiCalls/serverRequests/post";

interface LogOutUserProps extends RouteComponentProps<any> {
    history: History;
    renderComp: (logOut: () => void) => ReactNode;
}

const InnerLogOutUser: React.FunctionComponent<LogOutUserProps> = ({renderComp, history}): ReactElement | any => {
    const [loggedOut, setLoggedOut] = useState(false);
    const logOut: (user: User, setUser: (user: User) => void) => void = (user, setUser) => {
        setLoggedOut(true);
        setUser(USER_DEFAULT_VALUE);
        destroySession(user);
        removeUserToLocalStorage();
        history.push(getLogOutPath())
    }
    return (!loggedOut && (
            <UserContext.Consumer>
                {({user, setUser}) => renderComp(() => logOut(user, setUser))}
            </UserContext.Consumer>)
    )
}

export const LogOutUser = withRouter(InnerLogOutUser);