import React, {useState} from 'react';
import './App.css';
import {User, USER_DEFAULT_VALUE, UserContext} from './context/userContext';
import {CheckUserSession} from "./components/shared/user/CheckUserSession";
import {MuiPickersUtilsProvider} from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import {createMuiTheme, ThemeProvider} from '@material-ui/core';
import {BrowserRouter} from "react-router-dom";
import {Navigation} from "./components/shared/layout/Navigation";
import {AppRoutes} from "./routes/AppRoutes";

const theme = createMuiTheme({
    palette: {
        type: "dark",
        primary: {
            main: '#0090E0',
        }
    },
    shape: {
        borderRadius: 0,
    }
});

function App() {
    const [user, setUser] = useState<User>(USER_DEFAULT_VALUE);
    return (
        <UserContext.Provider value={{user, setUser}}>
            <CheckUserSession setUser={setUser}>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ThemeProvider theme={theme}>
                        <div className="App">
                            <BrowserRouter basename={process.env.PUBLIC_URL}>
                                <Navigation>
                                    <AppRoutes/>
                                </Navigation>
                            </BrowserRouter>
                        </div>
                    </ThemeProvider>
                </MuiPickersUtilsProvider>
            </CheckUserSession>
        </UserContext.Provider>);
}

export default App;
