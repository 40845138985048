import {baseUrl} from "./settings";
import {addUserToLocalStorage} from "../../utils/functions";
import {fetchPostWithAuth} from "../../utils/api";
import {Domain} from "../../types/api";
import {User} from "../../context/userContext";

export const logInUser = (email: User["email"], password: string) => {
    return fetchPostWithAuth(baseUrl + 'user/login', JSON.stringify({email, password}))
        .then(({result, status}) => {
            if (result && result.email && result.session_id) {
                addUserToLocalStorage(result.session_id, result.email);
            }
            return result
        })
}

export const validateUserSession = (session_id: string | null, email: string | null) => fetchPostWithAuth(
    baseUrl + 'user/session/validate', JSON.stringify({session_id, email})
)
export const deleteDomain = (domain_id: Domain["id"]) => fetchPostWithAuth(
    baseUrl + "domains/delete", JSON.stringify({domain_id})
)
export const addDomain = (domain: Domain["domain"], token: Domain["token"]) => fetchPostWithAuth(
    baseUrl + "domains/add", JSON.stringify({domain, token})
)
export const updateDomain = (domain: Domain['domain'], token: Domain["token"], domain_id: Domain["id"]) => fetchPostWithAuth(
    baseUrl + "domains/update", JSON.stringify({domain, token, domain_id})
)
export const destroySession = (user: User) => fetchPostWithAuth(
    baseUrl + "user/session/destroy", JSON.stringify({user_id: user.id, session_id: user.session_id})
)
export const changePassword = (password: string, new_password: string, user_id: number) => fetchPostWithAuth(
    baseUrl + "user/change_password", JSON.stringify({password, new_password, user_id})
)