import * as React from 'react';
import {createStyles, makeStyles, Theme} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import {faCogs, faList, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {IconButton} from "@material-ui/core";
import {dFlex, margins, mAuto, mLeftAuto} from "../../../constants/styles";
import {getChangePasswordPath, getLogInPath, getTokenPath} from "../../../routes/paths";
import {NoStyleLink} from "../NoStyleLink";
import {UserContext} from "../../../context/userContext";
import {LogOutUser} from '../LogOutUser';
import {isAdmin} from "../../../utils/functions";
import {APP_NAME} from "../../../constants/const";
import {IconProp} from "@fortawesome/fontawesome-svg-core";

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            display: 'flex',
        },
        appBar: {
            width: `calc(100% - ${drawerWidth}px)`,
            marginLeft: drawerWidth,
        },
        drawer: {
            width: drawerWidth,
            flexShrink: 0,
        },
        drawerPaper: {
            width: drawerWidth,
        },
        bgColor: {
            color: '#fff',
            fontWeight: 800,
            background: theme.palette.primary.main,
        },
        // necessary for content to be below app bar
        toolbar: theme.mixins.toolbar,
        content: {
            flexGrow: 1,
            backgroundColor: theme.palette.background.default,
            padding: theme.spacing(3),
        },
    }),
);
type NavigationProps = {
    children: any,
};

type LINK = {
    path: string,
    icon: IconProp,
    label: string,
}

const links: Array<LINK> = []

const adminLinks: Array<LINK> = [
    {
        path: getTokenPath(),
        icon: faList,
        label: "Token"
    },
    {
        path: getChangePasswordPath(),
        icon: faCogs,
        label: "Einstellungen"
    }
]

export const Navigation: React.FunctionComponent<NavigationProps> = ({children}) => {
    const classes = useStyles();
    return (
        <UserContext.Consumer>
            {({user, setUser}) => (
                <div className={classes.root}>
                    <CssBaseline/>
                    <AppBar position="fixed" className={user.id ? classes.appBar : ""}>
                        <Toolbar>
                            {!user.id && (
                                <NoStyleLink to={"/"}>
                                    <Typography variant="h6" noWrap>
                                        {APP_NAME}
                                    </Typography>
                                </NoStyleLink>
                            )}
                            <div style={mLeftAuto}>
                                <div style={dFlex}>
                                    {user.id ? (
                                        <LogOutUser renderComp={
                                            (logOut) => (
                                                <IconButton onClick={() => logOut()} edge="end" color="inherit">
                                                    <FontAwesomeIcon icon={faSignOutAlt}/>
                                                </IconButton>
                                            )}/>
                                    ) : (
                                        <NoStyleLink to={getLogInPath()}>
                                            <Typography>Log In</Typography>
                                        </NoStyleLink>
                                    )}
                                </div>
                            </div>
                        </Toolbar>
                    </AppBar>
                    {user.id && (
                        <Drawer className={classes.drawer} variant="permanent" classes={{paper: classes.drawerPaper}}
                                anchor="left">
                            <NoStyleLink to={getTokenPath()}>
                                <div className={classes.toolbar + " " + classes.bgColor} style={dFlex}>
                                    <Typography variant="h6" noWrap style={mAuto}>
                                        {APP_NAME}
                                    </Typography>
                                </div>
                            </NoStyleLink>
                            <Divider/>
                            <List>{links.map((link, index) => (
                                <NoStyleLink to={link.path} key={index}>
                                    <ListItem button>
                                        <FontAwesomeIcon icon={link.icon} style={{marginRight: margins['s']}}/>
                                        <ListItemText primary={link.label}/>
                                    </ListItem>
                                </NoStyleLink>
                            ))}
                                {isAdmin(user.user_type) && adminLinks.map((link, index) => (
                                    <NoStyleLink to={link.path} key={index}>
                                        <ListItem button>
                                            <FontAwesomeIcon icon={link.icon} style={{marginRight: margins['s']}}/>
                                            <ListItemText primary={link.label}/>
                                        </ListItem>
                                    </NoStyleLink>
                                ))}
                                <LogOutUser renderComp={
                                    (logOut) => (
                                        <ListItem button onClick={() => logOut()}>
                                            <FontAwesomeIcon icon={faSignOutAlt} style={{marginRight: margins['s']}}/>
                                            <ListItemText primary={"Log out"}/>
                                        </ListItem>
                                    )
                                }/>
                            </List>
                        </Drawer>
                    )}
                    <main className={classes.content}>
                        <div className={classes.toolbar}/>
                        {children}
                    </main>
                </div>
            )}
        </UserContext.Consumer>
    );
}

