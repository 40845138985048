import React from 'react';
import {Button, Modal, Paper, Typography} from "@material-ui/core";
import {dFlex, margins, mLeftAuto} from "../../constants/styles";
import {GridContainer} from "../../components/shared/layout/GridContainer";
import {GridItem} from "../../components/shared/layout/GridItem";
import {Domain} from "../../types/api";

type DeleteTokenModalProps = {
    domain: Domain
    onSubmit: () => void
    onCancel: () => void
}

export const DeleteTokenModal: React.FunctionComponent<DeleteTokenModalProps> = ({domain, onSubmit, onCancel}) => {
    return (<Modal
        open
        disableEnforceFocus
        disableAutoFocus
        onClose={onCancel}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
        style={dFlex}
    >
            <Paper style={{margin: "72px auto auto", padding: margins["m"], width: 1000, maxWidth: "100%"}}>
                <GridContainer>
                    <GridItem xs={12}>
                        <Typography>
                            Möchten Sie das Token für die Domain "{domain.domain}" wirklich löschen?
                        </Typography>
                    </GridItem>
                    <GridItem xs={12} style={dFlex}>
                        <Button onClick={onCancel} style={mLeftAuto}>abbrechen</Button>
                        <Button variant="contained" style={{ marginLeft: 16 }} onClick={onSubmit} color="secondary">löschen</Button>
                    </GridItem>
                </GridContainer>
            </Paper>
    </Modal>);
}