import * as React from 'react'

export const USER_DEFAULT_VALUE = {
    id: null,
    email: "",
    user_type: 0,
    session_id: "",
}

export type User = {
    id: null | number,
    email: string,
    user_type: number,
    session_id: string,
}
export type UserContextType = {
    user: User,
    setUser: (user: User) => void
}

export const UserContext = React.createContext<UserContextType>({
    user: USER_DEFAULT_VALUE,
    setUser: (user: User) => user,
});