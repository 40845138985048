import React, {useState} from 'react';
import {ApiController} from "../../controller/ApiController";
import {Button, Table, TableBody} from "@material-ui/core";
import {dFlex, mLeftAuto} from "../../constants/styles";
import {quickSort} from "../../utils/quicksort";
import {GridItem} from "../../components/shared/layout/GridItem";
import {GridContainer} from "../../components/shared/layout/GridContainer";
import {AddDomain} from "./addDomain/AddDomain";
import {SkeletonTableRows} from "../../components/shared/loader/SkeletonTableRows";
import {DisplayTokenRow} from "./DisplayTokenRow";
import {Domain, Domains} from "../../types/api";
import {getDomains} from "../../apiCalls/serverRequests/get";
import {DisplayTableHead} from "./DisplayTableHead";

type DisplayLicensesProps = {}

export type SearchObject = {
    column: string,
    value: string,
}

export const DisplayToken: React.FunctionComponent<DisplayLicensesProps> = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [initialValues, setInitialValues] = useState<Domain | null>(null);
    const [domains, setDomains] = useState<Domains | null>(null);
    const [updateLicenses, setUpdateLicenses] = useState<boolean>(true);
    const [isSorting, setIsSorting] = React.useState<boolean>(true);
    const [search, setSearch] = React.useState<{ [key: string]: SearchObject }>({});

    const successHandler = (result: typeof domains) => {
        setUpdateLicenses(false);
        setDomains(result);
        setIsSorting(false)
    }

    const sortDomains = (orderBy: string, order: string) => {
        if (domains) {
            let arrLength = domains.length;
            if (arrLength > 1) {
                // @ts-ignore
                quickSort(domains, 0, arrLength - 1, orderBy, order);
                setDomains([...domains]);
            }
        }
        setIsSorting(false);
    }

    const editDomain = (initialValues: Domain | null) => [setInitialValues(initialValues), setOpen(true)]

    return (
        <GridContainer>
            <GridItem xs={12} style={dFlex}>
                <div style={mLeftAuto}>
                    <Button variant="contained" color="primary" onClick={() => editDomain(null)}>
                        Neue Domain hinzufügen
                    </Button>
                    <AddDomain onSuccess={() => setUpdateLicenses(true)} open={open} setOpen={setOpen}
                               initialValues={initialValues}/>
                </div>
            </GridItem>
            <GridItem xs={12}>
                <Table component={"div"}>
                    <DisplayTableHead search={search} setSearch={setSearch} isSorting={isSorting}
                                      setIsSorting={setIsSorting} sortDomains={sortDomains}/>
                    <ApiController loading={updateLicenses} apiCall={getDomains} onSuccess={successHandler}
                                   loadingComponent={<SkeletonTableRows rowCount={20} colCount={4} component={"div"}/>}>
                        {() => domains && (
                            <TableBody component={"div"}>
                                <DisplayTokenRow domains={domains} search={search} setDomains={setDomains}
                                                 editDomain={editDomain}/>
                            </TableBody>
                        )}
                    </ApiController>
                </Table>
            </GridItem>
        </GridContainer>
    )
};