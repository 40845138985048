import React from "react";
import {Typography} from "@material-ui/core";
import {GridContainer} from "../../components/shared/layout/GridContainer";
import {GridItem} from "../../components/shared/layout/GridItem";

export const LoggedOut: React.FunctionComponent = () => (
    <React.Fragment>
        <GridContainer>
            <GridItem xs={12} marginType={"xxl"}>
                <Typography variant={"h2"}>Erfolgreich abgemeldet</Typography>
            </GridItem>
        </GridContainer>
    </React.Fragment>
)