import React from 'react';
import {IconButton, TableCell} from "@material-ui/core";
import {convertDateToFormatWithTime} from "../../utils/date";
import {SearchObject} from "./DisplayToken";
import LazyLoad from "react-lazyload";
import "../../css/lazyload.css";
import {SkeletonTableCells} from "../../components/shared/loader/SkeletonTableCells";
import {Domain, Domains} from "../../types/api";
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {deleteDomain} from "../../apiCalls/serverRequests/post";
import {DeleteTokenModal} from "./DeleteTokenModal";

type DisplayLicenseTableProps = {
    domains: Domains
    search: { [key: string]: SearchObject }
    setDomains: (domains: Domains) => void;
    editDomain: (initialValues: Domain | null) => void;
}

export const DisplayTokenRow: React.FunctionComponent<DisplayLicenseTableProps> = (
    {domains, search, setDomains, editDomain}
) => {
    const [domainToDelete, setDomainToDelete] = React.useState< Domain | null>(null);

    const searchColumns = () => Object.keys(search).map(key => {
            if (search[key] && search[key].value !== "") {
                // @ts-ignore
                domains = domains.filter(domain => domain[search[key].column].toLowerCase().includes(search[key].value.toLowerCase()))
            }
            return null;
        })
    const deleteRow = () => domainToDelete && deleteDomain(domainToDelete.id).then((result) => {
        if (result.http_code === 200) {
            setDomains([...domains.filter((domain) => domainToDelete.id !== domain.id)]);
            setDomainToDelete(null);
        }
    });
    searchColumns();
    return (
        <React.Fragment>
            {domains.map((domain, index) => domain && (
                <LazyLoad offset={200} key={index} placeholder={<SkeletonTableCells colCount={4} component={"div"}/>}>
                    <TableCell component={"div"}>
                        {domain.domain}
                    </TableCell>
                    <TableCell component={"div"}>
                        {domain.token}
                    </TableCell>
                    <TableCell component={"div"}>
                        {convertDateToFormatWithTime(domain.created_at)}
                    </TableCell>
                    <TableCell component={"div"}>
                        <IconButton color="primary" onClick={() => editDomain(domain)}>
                            <FontAwesomeIcon icon={faEdit}/>
                        </IconButton>
                        <IconButton color="secondary" onClick={() => setDomainToDelete(domain)}>
                            <FontAwesomeIcon icon={faTrash}/>
                        </IconButton>
                    </TableCell>
                </LazyLoad>
            ))}
            {domainToDelete && (
            <DeleteTokenModal domain={domainToDelete} onSubmit={deleteRow} onCancel={() => setDomainToDelete(null)} />
            )}
        </React.Fragment>
    );
}